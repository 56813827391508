export default [
  {
    title: 'Ana Sayfa',
    route: 'home',
    icon: 'home',
  },
  {
    title: 'Araç Parametreleri',
    icon: 'local_shipping',
    children: [
      {
        title: 'Marka Tanımlama',
        route: 'vehicle-brand',
        icon: 'branding_watermark',
      },
      {
        title: 'Model Tanımlama',
        route: 'vehicle-brand-model',
        icon: 'model_training',
      },
      {
        title: 'Model Serisi Tanımlama',
        route: 'vehicle-brand-model-serial',
        icon: 'unfold_less',
      },
      {
        title: 'Araç Tipi Tanımlama',
        route: 'vehicle-type',
        icon: 'rv_hookup',
      },
      {
        title: 'Araç Tanımlama',
        route: 'vehicle',
        icon: 'commute',
      },
    ]
  },
  {
    title: 'Güncel Veriler',
    icon: 'published_with_changes',
    children: [
      {
        title: 'Yakıt Fiyatları',
        route: 'fuel-prices',
        icon: 'local_gas_station',
      },
      {
        title: 'Döviz Kurları',
        route: 'currency',
        icon: 'currency_exchange',
      }
    ]
  },
  {
    title: 'Operasyonel Süreçler',
    icon: 'account_tree',
    children: [
      {
        title: 'Araç Envanter Tanımlama',
        route: 'vehicle-inventory-definition',
        icon: 'featured_play_list',
      },
      {
        title: 'Araç Belge Tanımlama',
        route: 'vehicle-inventory-document-definition',
        icon: 'picture_as_pdf',
      },
      {
        title: 'Garaj Tanımlama',
        route: 'garage',
        icon: 'garage',
      },
      {
        title: 'Garaj Görev Yeri Tanımlama',
        route: 'duty',
        icon: 'shopping_cart_checkout'
       
      },
      {
        title: 'Plaka Envanteri',
        route: 'plate',
        icon: 'tablet'
      }
    ]
  },
  {
    title: 'Taşınabilir Yükler',
    icon: 'precision_manufacturing',
    children: [
      {
        title: 'Ana Grup',
        route: 'shipable-product-main-group',
        icon: 'group_work',
      },
      {
        title: 'Alt Grup',
        route: 'shipable-product-sub-group',
        icon: 'category'
      },
      {
        title: 'Yük Şekli',
        route: 'shipable-product-form',
        icon: 'dashboard'
      },
      {
        title: 'Tehlike Sınıfı',
        route: 'shipable-product-danger-class',
        icon: 'local_fire_department'
      },
      {
        title: 'Değer Sınıfı',
        route: 'shipable-product-value-class',
        icon: 'diamond'
      },
      {
        title: 'Yükleme Modu',
        route: 'shipable-product-load-mode',
        icon: 'publish'
      },
      {
        title: 'Yükleme Tipi',
        route: 'shipable-product-load-type',
        icon: 'vertical_align_center'
      },
      {
        title: 'Yük',
        route: 'shipable-product',
        icon: 'inventory_2'
      },
    ]
  },
  {
    title: 'Sistem Tanımlamaları',
    icon: 'tune',
    children: [
      {
        title: 'Rol Yönetimi',
        route: 'role',
        icon: 'settings_accessibility',
      },
      {
        title: 'Mail Yönetimi',
        route: 'mail-settings',
        icon: 'mail_outline',
      },
      {
        title: 'Entegrasyon Bilgileri',
        route: 'integration-information',
        icon: 'integration_instructions',
      }
    ]
  },
  {
    title: 'Operasyonel Parametreler',
    icon: 'extension',
    children: [
      {
        title: 'Çalışma Grubu Tanımlama',
        route: 'working-group',
        icon: 'diversity_2',
      },
      {
        title:'Nakliyeci Sınıfı',
        route:'carrier-classification',
        icon:'stroller'
      }
    ]
  },
  {
    title: 'Fiyat Hesaplama',
    icon: 'calculate',
    children: [
      {
        title: 'Araç Referans Model',
        route: 'vehicle-reference-model',
        icon: 'architecture',
      },
      {
        title: 'Taslak Fiyat',
        route: 'draft-price',
        icon: 'design_services',
      },
    ]
  },
  {
    title: 'Kullanıcı Yönetimi',
    route: 'user',
    icon: 'account_circle',
  },
  {
    title: 'Raporlar',
    icon: 'summarize',
    children: [
      {
        title: 'Narlı Feribot Bilgileri',
        route: 'narli-ferryboat',
        icon: 'directions_boat',
      }
    ]
  },
  {
    title: 'Firma Yönetimi',
    icon: 'business',
    children: [
      
    ]
  },
]

