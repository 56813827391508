<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="12">
      <b-row class="align-items-center breadcrumbs-top">
        <b-col cols="12">
          
          <h3 class="content-header-title float-left pr-1 mb-0 d-flex align-items-center">
            <span v-if="isMobile" v-on:click="toggleCollapse()" class="material-icons-round mr-2 text-primary menu-icon">menu</span> 
            <span class="material-icons-outlined mr-50">{{$route.meta.icon}}</span> {{ $route.meta.pageTitle }}
          </h3>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <span class="material-icons-round mr-50 breadcrumb-home-icon">home</span>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  methods:{
    toggleCollapse() {
      useVerticalLayout().toggleVerticalMenuActive()
      useVerticalLayout().isVerticalMenuCollapsed.value = !useVerticalLayout().isVerticalMenuCollapsed.value
    },
  },
  computed:{
    isMobile(){
      return useVerticalLayout().isMobile.value
    },
    isVerticalMenuCollapsed(){
      return useVerticalLayout().isVerticalMenuCollapsed.value
    },
  }
}
</script>

<style scoped>
  .breadcrumb-home-icon{
    font-size: 20px;
  }
  .menu-icon{
    font-size: 30px;
    cursor: pointer;
    border-right: 1px solid #d6dce1;
    padding-right: 1rem;
  }
</style>